<template>
  <v-app>
    <v-app-bar app>

      <v-spacer></v-spacer> <!-- Ensures the logo stays centered -->

      <v-toolbar-title class="text-center flex-grow-1">
        <v-img src="@/assets/eslg2.png" contain alt="Logo" max-height="30" />
      </v-toolbar-title>
      
      <v-spacer></v-spacer> <!-- Ensures the logo stays centered -->

    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <!-- Navigation items -->
    </v-navigation-drawer>

    <v-main>
      <IoTDash />
    </v-main>
  </v-app>
</template>

<script>
import IoTDash from './components/IoTDash.vue';

export default {
  name: 'App',
  components: {
    IoTDash
  },
  data: () => ({
    drawer: false,
  }),
};
</script>

<style>
/* Add any additional styles if needed */
</style>
