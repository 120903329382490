// file BarChart.vue
<template>
  <canvas ref="chartRef"></canvas>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default defineComponent({
  name: 'BarChart',
  props: {
    chartData: Object,
    chartOptions: Object,
  },
  setup(props) {
    const chartRef = ref(null);

    onMounted(() => {
      if (chartRef.value) {
        new Chart(chartRef.value.getContext('2d'), {
          type: 'bar', // or 'horizontalBar' for Chart.js 2.x
          data: props.chartData,
          options: props.chartOptions,
        });
      }
    });

    return {
      chartRef,
    };
  },
});
</script>

<style scoped>
/* Component-specific styles here */
</style>
