<template>
  <div>
    <!-- PayPal button will be rendered inside this div -->
    <div ref="paypalButtonContainer"></div>
  </div>
</template>

<script>
/* global paypal */
export default {
  name: 'PayPalButton',
  props: {
    packageOption: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // Load the PayPal SDK script dynamically if not already loaded
    if (typeof paypal === 'undefined') {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_PROD_ID}&disable-funding=paylater`;
      script.onload = this.renderPayPalButton;
      document.body.appendChild(script);
    } else {
      this.renderPayPalButton();
    }
  },
  methods: {

    renderPayPalButton() {
      console.log('Rendering PayPal button, packageOption:', this.packageOption);
      if (!this.packageOption || typeof this.packageOption.pkgcost === 'undefined') {
        console.error('Package option or cost is undefined:', this.packageOption);
        return;
      }

      paypal.Buttons({
        createOrder: (data, actions) => {
          const cost = this.extractNumericValue(this.packageOption.pkgcost);
          console.log('Creating order with cost:', cost);
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: cost
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(details => {
            this.$emit('payment-success', details);
          });
        }
      }).render(this.$refs.paypalButtonContainer);
    },
    extractNumericValue(str) {
      const match = str.match(/\d+(\.\d+)?/);
      return match[0];
    }
  }
};
</script>

